export class AppSettings {
  //public static readonly API_INSCRIPTIONS = `http://localhost:8080/api`;
  // public static readonly API_COMPATIBILITY = `https://saz.softmart.mx/api-softmart-saz/src`;

  public static readonly API_INSCRIPTIONS = `https://test.integraltivo.com/api`;
  public static readonly API_COMPATIBILITY = `https://saz.softmart.mx/api-softmart-saz/src`;

  // public static readonly API_INSCRIPTIONS = `https://integraltivo.com/api`;
  // public static readonly API_COMPATIBILITY = `https://saz.softmart.mx/api-softmart-saz/src`;

}
